import React from "react"
import { Typeahead } from "react-bootstrap-typeahead"
import { Input, Row, Col } from "reactstrap"
import moment from "moment"
import { getEmployees } from "../../redux/actions/employeeActions"
import {
  getActivities,
  updateActivity,
} from "../../redux/actions/activityActions"
import MonthTable from "./_components/monthTable"
import { connect } from "react-redux"

class Overview extends React.Component {
  state = {
    currentDate: moment(),
    selectedYear: moment().format("YYYY"),
    selectedMonth: moment().format("MMMM"),
    selectedEmployee: {},
    activities: [],
  }

  componentWillMount() {
    this.props.getEmployees()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activity.activities !== nextProps.activity.activities) {
      this.setState({
        activities: nextProps.activity.activities,
      })
    }
  }

  handleUpdate = (id, newValue, type) => {
    console.log("Update the change.")
    const selectedActivity = this.props.activity.activities.filter(
      activity => activity.id === id
    )[0]

    selectedActivity[type] = moment(newValue).valueOf()

    this.props.updateActivity(id, selectedActivity)
  }

  getData = () => {
    if (this.state.selectedEmployee.length > 0) {
      console.log("Get data from server")
      const selectedDate = moment()
        .month(this.state.selectedMonth)
        .year(this.state.selectedYear)

      const startDate = selectedDate.startOf("month").format("YYYY-MM-DD")
      const endDate = selectedDate.endOf("month").format("YYYY-MM-DD")

      this.props.getActivities({
        cardId: this.state.selectedEmployee[0].id,
        startDate: startDate,
        endDate: endDate,
      })
    }
  }

  render() {
    let employees = []

    if (this.props.employee.employees.length) {
      employees = this.props.employee.employees.map(employee => {
        return {
          name: `${employee.firstName} ${employee.lastName}`,
          id: employee.cardId,
        }
      })
    }

    return (
      <div className="aphrm-c-overview">
        <Row className="aphrm-c-overview__title">
          <Col>
            <h3>Pregled</h3>
          </Col>
          <Col md="2">
            <Input
              type="select"
              className="aphrm-c-overview__months"
              value={this.state.selectedMonth}
              onChange={e => {
                this.setState({ selectedMonth: e.target.value }, () =>
                  this.getData()
                )
              }}
            >
              {moment.months().map((month, i) => (
                <option key={i} value={month}>
                  {month}
                </option>
              ))}
            </Input>
          </Col>
          <Col md="2">
            <Input
              type="select"
              value={this.state.selectedYear}
              onChange={e => {
                this.setState({ selectedYear: e.target.value }, () =>
                  this.getData()
                )
              }}
            >
              {[...Array(5)].map((x, i) => (
                <option
                  key={i}
                  value={this.state.currentDate.format("YYYY") - i}
                >
                  {this.state.currentDate.format("YYYY") - i}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typeahead
              labelKey="name"
              value={this.state.selectedEmployee}
              multiple={false}
              onChange={e => {
                this.setState({ selectedEmployee: e }, () => this.getData())
              }}
              options={employees}
              placeholder="Delavec..."
            />
          </Col>
        </Row>
        <div className="aphrm-c-overview__data-holder">
          {Object.keys(this.state.selectedEmployee).length === 0 ? (
            <div>Izberite delavca...</div>
          ) : (
            <MonthTable
              month={this.state.selectedMonth}
              year={this.state.selectedYear}
              data={this.state.activities}
              handleUpdate={(id, newValue, type) =>
                this.handleUpdate(id, newValue, type)
              }
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    employee: state.employee,
    activity: state.activity,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getEmployees: () => dispatch(getEmployees()),
    getActivities: postData => dispatch(getActivities(postData)),
    updateActivity: (activityId, postData) =>
      dispatch(updateActivity(activityId, postData)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview)

const INITIAL_STATE = {
  registered: false,
  removed: false,
  updated: false,
  error: null,
  loaded: false,
  users: [],
  user: {},
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_USERS":
      console.log("Get users", action.payload)
      return {
        ...state,
        users: action.payload,
        registered: false,
        removed: false,
        updated: false,
        loaded: true,
        error: null,
      }
    case "GET_USER":
      console.log("Get user", action.payload)
      return { ...state, user: action.payload }
    case "CREATE_USER":
      console.log("Created user", action.payload)
      return { ...state, user: {}, registered: true }
    case "UPDATE_USER":
      console.log("User updated", action.payload)
      return { ...state, user: {}, updated: true }
    case "DELETE_USER":
      console.log("User removed", action.payload)
      return { ...state, user: {}, removed: true }
    case "CREATE_USER_ERROR":
      console.log("Create user error ", action.payload)
      return {
        ...state,
        registrationUser: { registered: false, error: action.payload },
      }
    default:
      return state
  }
}

export default userReducer

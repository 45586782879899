import React from "react"
import { Table, Row, Col } from "reactstrap"
import { Link, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { hasPermissions } from "../../lib/checkRole"
import { getUsers } from "../../redux/actions/userActions"

class Users extends React.Component {
  componentWillMount() {
    this.props.getUsers()
  }

  render() {
    if (this.props.firebaseProfile.isEmpty) return null

    if (!hasPermissions(this.props.firebaseProfile.role, ["admin"]))
      return <Redirect to="/404" />
    const { users, loaded } = this.props.user
    if (!loaded) return null
    return (
      <div className="aphrm-c-users">
        <Row>
          <Col md={10}>
            <h3>Uporabniki</h3>
          </Col>
          <Col md={2} className="text-right">
            <Link to={"/uporabniki/nov"}>
              <i className="fal fa-plus-circle aphrm-c-users__add" />
            </Link>
          </Col>
        </Row>

        <Table striped className="users-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Uporabnik</th>
              <th>Email</th>
              <th>Podjetje</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              loaded &&
              users.map((user, id) => (
                <tr
                  onClick={() =>
                    this.props.history.push(`/uporabniki/${user.id}`)
                  }
                  key={user.id}
                >
                  <th scope="row">{id + 1}</th>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.organisation.name}</td>
                </tr>
              ))}
            {users.length <= 0 && (
              <tr>
                <td colSpan="4" className="text-center">
                  Ni uporabnikov
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    firebaseProfile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => dispatch(getUsers()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users)

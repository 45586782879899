import React from "react"
import { Table, Row, Col } from "reactstrap"
import moment from "moment"

import EditEntry from "./_components/editEntry"

class MonthTable extends React.Component {
  constructor(props) {
    super(props)
    this.totalSum = 0
  }

  componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      this.totalSum = 0
    }
  }

  render() {
    moment.locale("sl")
    return (
      <div className="aphrm-c-month-table">
        <Row>
          <Col>
            <h3 className="aphrm-c-month-table__title">
              {this.props.month} {this.props.year}
            </h3>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Dan</th>
                  <th>Prihod</th>
                  <th>Odhod</th>
                  <th>Malica</th>
                  <th>Potovanja</th>
                  <th>Skupaj</th>
                </tr>
              </thead>

              <tbody>
                {this.props.data.map((row, index) => {
                  this.totalSum += row.workingTotal
                    ? parseFloat(row.workingTotal)
                    : 0
                  return (
                    <tr className="" key={index}>
                      <th scope="row">
                        {moment(row.workingStart).format("DD.MM.YYYY")}
                      </th>
                      <td>
                        <EditEntry
                          data={row.workingStart}
                          handleUpdate={newValue => {
                            this.props.handleUpdate(
                              row.id,
                              newValue,
                              "workingStart"
                            )
                          }}
                        />
                      </td>
                      <td>
                        <EditEntry
                          data={row.workingStop}
                          handleUpdate={newValue =>
                            this.props.handleUpdate(
                              row.id,
                              newValue,
                              "workingStop"
                            )
                          }
                        />
                      </td>
                      <td>{row.eatingTotal}</td>
                      <td>{row.tripTotal}</td>
                      <td>{row.workingTotal}</td>
                    </tr>
                  )
                })}

                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>Skupaj</td>
                  <th scope="row">{this.totalSum.toFixed(2)}</th>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    )
  }
}

export default MonthTable

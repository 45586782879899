import http from "../../lib/httpService"

export const getActivities = postData => {
  return async dispatch => {
    try {
      const response = await http.post("/activities", postData)
      dispatch({ type: "GET_ACTIVITIES", payload: response })
    } catch (err) {
      dispatch({ type: "GET_ACTIVITIES_ERROR", payload: err })
    }
  }
}

export const updateActivity = (activityId, postData) => {
  return async dispatch => {
    try {
      const response = await http.post(`/activities/${activityId}`, postData)
      dispatch({ type: "UPDATE_ACTIVITY", payload: response })
    } catch (err) {
      dispatch({ type: "UPDATE_ACTIVITY_ERROR", payload: err })
    }
  }
}

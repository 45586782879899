import http from "../../lib/httpService"

export const createUser = user => {
  return async dispatch => {
    try {
      await http.put("/users", user)

      dispatch({ type: "CREATE_USER" })
    } catch (err) {
      dispatch({ type: "CREATE_USER_ERROR", payload: err })
    }
  }
}

export const getUser = userId => {
  return async dispatch => {
    try {
      const response = await http.get(`/users/${userId}`)
      dispatch({ type: "GET_USER", payload: response })
    } catch (err) {
      dispatch({ type: "GET_USER_ERROR", payload: err })
    }
  }
}

export const updateUser = (userId, userData) => {
  return async dispatch => {
    try {
      const response = await http.post(`/users/${userId}`, userData)
      dispatch({ type: "UPDATE_USER", payload: response })
    } catch (err) {
      dispatch({ type: "UPDATE_USER_ERROR", payload: err })
    }
  }
}

export const deleteUser = userId => {
  return async dispatch => {
    try {
      const response = await http.delete(`/users/${userId}`)
      dispatch({ type: "DELETE_USER", payload: response })
    } catch (err) {
      dispatch({ type: "DELETE_USER_ERROR", payload: err })
    }
  }
}

export const getUsers = () => {
  return async dispatch => {
    try {
      const response = await http.get("/users")
      dispatch({ type: "GET_USERS", payload: response })
    } catch (err) {
      dispatch({ type: "GET_USERS_ERROR", payload: err })
    }
  }
}

const INITIAL_STATE = {
  lastActivity: "",
  status: "",
  employee: {
    firstName: "",
    lastName: "",
    company: "",
  },
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_STATUS":
      console.log("Get status", action.payload)
      return {
        ...state,
        ...action.payload,
      }
    case "GET_STATUS_ERROR":
      console.log("Get status error ", action.payload)
      return state
    default:
      return state
  }
}

export default userReducer

import React, { Component } from "react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Framework from "./pages/framework";
import Page404 from "./pages/404";
import "./App.scss";

import Login from "./pages/login";

/* LogRocket Init */
LogRocket.init("vc6pzb/aptohrm-manager");
setupLogRocketReact(LogRocket);

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/404" component={Page404} exact />
            <Framework history={this.props.history} />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

import React from "react";
import { Form, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import {
  createUser,
  getUser,
  deleteUser,
  updateUser
} from "../../../redux/actions/userActions";
import { hasPermissions } from "../../../lib/checkRole";
import { Redirect } from "react-router-dom";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstName: "",
        lastName: "",
        organisation: "",
        email: "",
        password: "",
        passwordConfirm: ""
      },
      error: null,
      existingUser: false
    };
  }

  componentWillMount() {
    if (this.props.match.params.userId) {
      this.props.getUser(this.props.match.params.userId);
      this.setState({ existingUser: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (
        nextProps.userRegistered ||
        nextProps.userRemoved ||
        nextProps.userUpdated
      ) {
        this.props.history.push("/uporabniki");
      } else {
        if (this.props.user !== nextProps.user) {
          this.setState({
            user: {
              firstName: nextProps.user.firstName,
              lastName: nextProps.user.lastName,
              organisation: nextProps.user.organisation.name,
              email: nextProps.user.email
            }
          });
        }
      }
    }
  }

  saveUser = e => {
    e.preventDefault();

    if (this.state.existingUser) {
      const {
        organisation,
        email,
        password,
        passwordConfirm,
        ...request
      } = this.state.user;
      this.props.updateUser(this.props.match.params.userId, request);
    } else {
      if (this.state.user.password === this.state.user.passwordConfirm) {
        const { passwordConfirm, ...request } = this.state.user;
        this.props.createUser(request);
      } else {
        this.setState({
          error: "Geslo se ne ujema."
        });
      }
    }
  };

  deleteUser = e => {
    e.preventDefault();
    this.props.deleteUser(this.props.match.params.userId);
  };

  handleChange = e => {
    this.setState({
      user: {
        ...this.state.user,
        [e.target.id]: e.target.value
      },
      error: null
    });
  };

  render() {
    if (this.props.firebaseProfile.isEmpty) return null;

    if (!hasPermissions(this.props.firebaseProfile.role, ["admin"]))
      return <Redirect to="/404" />;

    const { user } = this.state;

    return (
      <div>
        <h3>Uporabnik</h3>
        <Form onSubmit={this.saveUser}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="firstName">Ime</Label>
                <Input
                  type="text"
                  required
                  name="firstName"
                  id="firstName"
                  value={user.firstName}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lastName">Priimek</Label>
                <Input
                  type="text"
                  required
                  name="lastName"
                  id="lastName"
                  value={user.lastName}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="organisation">Podjetje</Label>
                <Input
                  type="text"
                  required
                  name="organisation"
                  id="organisation"
                  value={user.organisation}
                  disabled={this.state.existingUser}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  required
                  name="email"
                  id="email"
                  value={user.email}
                  disabled={this.state.existingUser}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          {!this.state.existingUser && (
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="password">Geslo</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    required
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="passwordConfirm">Potrdi Geslo</Label>
                  <Input
                    type="password"
                    required
                    name="passwordConfirm"
                    id="passwordConfirm"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row form>
            <Col md={6}>{this.state.error}</Col>
          </Row>
          <Row form>
            <Col md={6}>
              <Button color="primary">Shrani</Button>
            </Col>
            {this.state.existingUser && (
              <Col md={6} className="text-right">
                <Button color="danger" onClick={this.deleteUser}>
                  Delete
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    firebaseProfile: state.firebase.profile,
    userRegistered: state.user.registered,
    userRemoved: state.user.removed,
    userUpdated: state.user.updated,
    user: state.user.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUser: user => dispatch(createUser(user)),
    getUser: userId => dispatch(getUser(userId)),
    deleteUser: userId => dispatch(deleteUser(userId)),
    updateUser: (userId, payload) => dispatch(updateUser(userId, payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);

import React from "react"
import ReactDOM from "react-dom"
import "bootstrap/dist/css/bootstrap.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "moment/locale/sl"

import { createStore, applyMiddleware, compose } from "redux"
import reducers from "./redux/reducers"
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import { reduxFirestore } from "redux-firestore"
import { reactReduxFirebase, getFirebase } from "react-redux-firebase"
import firebase from "./config/firebaseConfig"

const store = createStore(
  reducers,
  compose(
    applyMiddleware(
      thunk.withExtraArgument({
        getFirebase,
      })
    ),
    reduxFirestore(firebase),
    reactReduxFirebase(firebase, {
      useFirestoreForProfile: true,
      userProfile: "users",
      attachAuthIsReady: true,
    })
  )
)

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister()
})

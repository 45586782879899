import React from "react";
import classNames from "classnames";
import moment from "moment";

class Entry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      inputValue: moment(this.props.data).format("HH:mm"),
      originalValue: moment(this.props.data).format("HH:mm")
    };
    this.textInput = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({
        inputValue: moment(nextProps.data).format("HH:mm"),
        originalValue: moment(nextProps.data).format("HH:mm")
      });
    }
  }

  saveRecord = () => {
    const parseTime = this.state.inputValue.split(":");
    let response = moment(this.props.data)
      .set("hour", parseTime[0])
      .set("minute", parseTime[1]);
    this.props.handleUpdate(response.format("YYYY-MM-DD HH:mm"));
    this.setState({ editable: false });
  };

  revertRecord = () => {
    this.setState({ editable: false, inputValue: this.state.originalValue });
  };

  startEditing = () => {
    if (this.state.editable === false)
      this.setState({ editable: true }, () => {
        this.textInput.current.focus();
      });
  };

  render() {
    const rowClasses = classNames("aphrm-c-month-table__editable", {
      "aphrm-c-month-table__editable--edit": this.state.editable
    });

    const { inputValue, editable } = this.state;
    return (
      <div className={rowClasses} onClick={() => this.startEditing()}>
        <input
          type="time"
          min="00:00"
          max="23:59"
          className="aphrm-c-month-table__editable__input form-control"
          value={inputValue}
          onChange={e => {
            this.setState({
              inputValue: e.target.value
            });
          }}
          disabled={!editable}
          ref={this.textInput}
        />
        <div className="aphrm-c-month-table__editable__actions">
          <button onClick={() => this.saveRecord()}>
            <i className="fal fa-check" />
          </button>
          <button onClick={() => this.revertRecord()}>
            <i className="fal fa-times" />
          </button>
        </div>
      </div>
    );
  }
}

export default Entry;

import React from "react"
import { Row, Col, Input, Label, Button, FormGroup } from "reactstrap"
import { connect } from "react-redux"
import { getStatus, setActivity } from "../../redux/actions/actionActions"

class Actions extends React.Component {
  state = {
    cardId: "SV9JyIbsbGPY4SO0R8WJ",
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  setActivity = type => {
    console.log(type)
  }

  render() {
    const { lastActivity, status, employee } = this.props.status
    return (
      <div className="aphrm-c-users">
        <Row>
          <Col md={10}>
            <h3>Akcije</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="cardId">Ključ:</Label>
              <Input
                id="cardId"
                type="text"
                value={this.state.cardId}
                onChange={this.handleChange}
              />
              <Button onClick={() => this.props.getStatus(this.state.cardId)}>
                Pridobi podatke
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {lastActivity === "" && status === "WORKING_STOP" && (
            <Col md={6}>
              <Button
                onClick={() => {
                  this.props.setActivity(
                    this.state.cardId,
                    "WORKING_START",
                    null
                  )
                }}
              >
                Prihod
              </Button>
            </Col>
          )}
          {lastActivity !== "" && status === "WORKING_START" && (
            <Col md={6}>
              <Button
                onClick={() => {
                  this.props.setActivity(
                    this.state.cardId,
                    "WORKING_STOP",
                    lastActivity
                  )
                }}
              >
                Odhod
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          {lastActivity !== "" && status === "WORKING_START" && (
            <Col md={6}>
              <Button
                onClick={() => {
                  this.props.setActivity(
                    this.state.cardId,
                    "EATING_START",
                    lastActivity
                  )
                }}
              >
                Malica
              </Button>
            </Col>
          )}
          {lastActivity !== "" && status === "EATING_START" && (
            <Col md={6}>
              <Button
                onClick={() => {
                  this.props.setActivity(
                    this.state.cardId,
                    "EATING_STOP",
                    lastActivity
                  )
                }}
              >
                Stop Malica
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          {lastActivity !== "" && status === "WORKING_START" && (
            <Col md={6}>
              <Button
                onClick={() => {
                  this.props.setActivity(
                    this.state.cardId,
                    "TRIP_START",
                    lastActivity
                  )
                }}
              >
                Potovanje Start
              </Button>
            </Col>
          )}
          {lastActivity !== "" && status === "TRIP_START" && (
            <Col md={6}>
              <Button
                onClick={() => {
                  this.props.setActivity(
                    this.state.cardId,
                    "TRIP_STOP",
                    lastActivity
                  )
                }}
              >
                Potovanje Stop
              </Button>
            </Col>
          )}
        </Row>
        Debug:
        <br />
        lastActivity:{lastActivity}
        <br />
        status:{status}
        <br />
        user: {employee.firstName} {employee.lastName} {employee.company}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    status: state.status,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getStatus: cardId => dispatch(getStatus(cardId)),
    setActivity: (cardId, status, lastActivity) =>
      dispatch(setActivity(cardId, status, lastActivity)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions)

const INITIAL_STATE = {
  created: false,
  removed: false,
  updated: false,
  error: null,
  employees: [],
  employee: {}
};

const employeeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_EMPLOYEES":
      console.log("Get employees", action.payload);
      return {
        ...state,
        employees: action.payload,
        created: false,
        removed: false,
        updated: false,
        error: null
      };
    case "GET_EMPLOYEES_ERROR":
      console.log("Get employees ERROR", action.payload);
      return {
        ...state,
        employees: [],
        created: false,
        removed: false,
        updated: false,
        error: action.payload
      };
    case "GET_EMPLOYEE":
      console.log("Get employee", action.payload);
      return { ...state, employee: action.payload };
    case "CREATE_EMPLOYEE":
      console.log("Created employee", action.payload);
      return { ...state, employee: {}, created: true };
    case "UPDATE_EMPLOYEE":
      console.log("Employee updated", action.payload);
      return { ...state, employee: {}, updated: true };
    case "DELETE_EMPLOYEE":
      console.log("Employee removed", action.payload);
      return { ...state, employee: {}, removed: true };
    case "CREATE_EMPLOYEE_ERROR":
      console.log("Create employee error ", action.payload);
      return {
        ...state,
        created: { created: false, error: action.payload }
      };
    default:
      return state;
  }
};

export default employeeReducer;

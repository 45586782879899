import React from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap"

import { NavLink, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { logout } from "../../redux/actions/authActions"
import { hasPermissions } from "../../lib/checkRole"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      user: null,
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  logout = () => {
    this.props.logout()
  }

  render() {
    if (this.props.firebaseProfile.isEmpty === true) return null

    return (
      <Navbar color="light" light expand="md" className="aphrm-c-header">
        <NavbarBrand onClick={() => this.props.history.push("/")}>
          APTOHrm
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink to={"/"} exact className="nav-link">
                Domov
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={"/pregled"} className="nav-link">
                Pregled
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={"/delavci"} className="nav-link">
                Delavci
              </NavLink>
            </NavItem>
            {hasPermissions(this.props.firebaseProfile.role, ["admin"]) && (
              <NavItem>
                <NavLink to={"/uporabniki"} className="nav-link">
                  Uporabniki
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <Nav className="ml-auto" navbar>
            <span
              onClick={() => this.logout()}
              className={"nav-link aphrm-c-header__logout"}
            >
              Odjava
            </span>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  }
}

const mapStateToProps = state => {
  return {
    firebaseProfile: state.firebase.profile,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
)

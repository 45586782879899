import React from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { signIn } from "../../redux/actions/authActions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  login = e => {
    e.preventDefault();
    this.props.signIn({
      email: this.state.email,
      password: this.state.password
    });
  };

  render() {
    if (this.props.firebaseAuth.isEmpty !== true) return <Redirect to="/" />;

    return (
      <div className="aphrm-c-login">
        <Container fluid={false}>
          <form onSubmit={this.login}>
            <Row>
              <Col sm={{ size: 4, offset: 4 }} className="text-center">
                <h3 className="aphrm-c-login__logo">APTOHrm</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={{ size: 4, offset: 4 }}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={{ size: 4, offset: 4 }}>
                <FormGroup>
                  <Label for="password">Geslo</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={{ size: 4, offset: 4 }} className="text-center">
                <Button color="primary">Prijava</Button>
              </Col>
            </Row>
            {this.props.authError && (
              <Row>
                <Col sm={{ size: 4, offset: 4 }} className="text-center">
                  {this.props.authError.message}
                </Col>
              </Row>
            )}
          </form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    firebaseAuth: state.firebase.auth,
    authError: state.auth.authError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signIn: credentials => dispatch(signIn(credentials))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

const firebaseConfig = {
  development: {
    apiKey: "AIzaSyB7KxUtlNOkDkh0VcnTqwHyBcYEJVw2TBQ",
    authDomain: "aphrm-cd84d.firebaseapp.com",
    databaseURL: "https://aphrm-cd84d.firebaseio.com",
    projectId: "aphrm-cd84d",
    storageBucket: "aphrm-cd84d.appspot.com",
    messagingSenderId: "1018003371050",
  },
  production: {
    apiKey: "AIzaSyB7KxUtlNOkDkh0VcnTqwHyBcYEJVw2TBQ",
    authDomain: "aphrm-cd84d.firebaseapp.com",
    databaseURL: "https://aphrm-cd84d.firebaseio.com",
    projectId: "aphrm-cd84d",
    storageBucket: "aphrm-cd84d.appspot.com",
    messagingSenderId: "1018003371050",
  },
}

firebase.initializeApp(firebaseConfig[process.env.REACT_APP_ENVIRONMENT])

firebase.firestore().settings({ timestampsInSnapshots: true })

export default firebase

import firebase from "../config/firebaseConfig"
const API_URL = process.env.REACT_APP_API_SERVER

export default {
  get: async to => {
    try {
      const token = await firebase.auth().currentUser.getIdToken()
      const response = await fetch(`${API_URL}${to}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      })

      const data = await response.json()
      return data
    } catch (err) {
      return err
    }
  },
  post: async (to, payload = {}) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken()
      const response = await fetch(`${API_URL}${to}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        method: "POST",
        body: JSON.stringify(payload),
      })

      const data = await response.json()
      return data
    } catch (err) {
      return err
    }
  },
  put: async (to, payload = {}) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken()
      const response = await fetch(`${API_URL}${to}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        method: "PUT",
        body: JSON.stringify(payload),
      })

      const data = await response.json()
      return data
    } catch (err) {
      return err
    }
  },
  patch: async (to, payload = {}) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken()
      const response = await fetch(`${API_URL}${to}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        method: "PATCH",
        body: JSON.stringify(payload),
      })

      const data = await response.json()
      return data
    } catch (err) {
      return err
    }
  },
  delete: async to => {
    try {
      const token = await firebase.auth().currentUser.getIdToken()
      const response = await fetch(`${API_URL}${to}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        method: "DELETE",
      })

      const data = await response.json()
      return data
    } catch (err) {
      return err
    }
  },
}

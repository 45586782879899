import React from "react"
import { Form, Row, Col, FormGroup, Label, Input, Button } from "reactstrap"
import { connect } from "react-redux"
import {
  createEmployee,
  getEmployee,
  deleteEmployee,
  updateEmployee,
} from "../../../redux/actions/employeeActions"

class Employee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      employee: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        cardId: "",
      },
      error: null,
      existingEmployee: false,
    }
  }

  componentWillMount() {
    if (this.props.match.params.employeeId) {
      this.props.getEmployee(this.props.match.params.employeeId)
      this.setState({ existingEmployee: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (
        nextProps.employeeCreated ||
        nextProps.employeeRemoved ||
        nextProps.employeeUpdated
      ) {
        this.props.history.push("/delavci")
      } else {
        if (this.props.employee !== nextProps.employee) {
          this.setState({
            employee: {
              firstName: nextProps.employee.firstName,
              lastName: nextProps.employee.lastName,
              email: nextProps.employee.email,
              phone: nextProps.employee.phone,
              cardId: nextProps.employee.cardId,
            },
          })
        }
      }
    }
  }

  saveEmployee = e => {
    e.preventDefault()

    if (this.state.existingEmployee) {
      this.props.updateEmployee(
        this.props.match.params.employeeId,
        this.state.employee
      )
    } else {
      this.props.createEmployee(this.state.employee)
    }
  }

  deleteEmployee = e => {
    e.preventDefault()
    this.props.deleteEmployee(this.props.match.params.employeeId)
  }

  handleChange = e => {
    this.setState({
      employee: {
        ...this.state.employee,
        [e.target.id]: e.target.value,
      },
      error: null,
    })
  }

  render() {
    const { employee } = this.state

    return (
      <div>
        <h3>Delavec</h3>
        <Form onSubmit={this.saveEmployee}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="firstName">Ime</Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  required
                  value={employee.firstName}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lastName">Priimek</Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  required
                  value={employee.lastName}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={employee.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">Telefon</Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  value={employee.phone}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="card">Ključ</Label>
                <Input
                  type="text"
                  name="card"
                  id="card"
                  disabled
                  value={employee.cardId}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <Button color="primary">Shrani</Button>
            </Col>
            {this.state.existingEmployee && (
              <Col md={6} className="text-right">
                <Button color="danger" onClick={this.deleteEmployee}>
                  Delete
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    firebaseProfile: state.firebase.profile,
    employeeCreated: state.employee.created,
    employeeRemoved: state.employee.removed,
    employeeUpdated: state.employee.updated,
    employee: state.employee.employee,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createEmployee: employee => dispatch(createEmployee(employee)),
    getEmployee: employeeId => dispatch(getEmployee(employeeId)),
    deleteEmployee: employeeId => dispatch(deleteEmployee(employeeId)),
    updateEmployee: (employeeId, payload) =>
      dispatch(updateEmployee(employeeId, payload)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Employee)

const INITIAL_STATE = {
  employees: [],
}

const organisationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_STATISTICS":
      console.log("Get::statistics", action.payload)
      return {
        ...state,
        employees: action.payload,
      }
    case "GET_STATISTICS_ERROR":
      console.log("Get::statistics-Error ", action.payload)
      return {
        ...state,
        employees: [],
      }
    default:
      return state
  }
}

export default organisationReducer

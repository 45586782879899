const INITIAL_STATE = {
  authError: null
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, authError: null };
    case "LOGIN_ERROR":
      return { ...state, authError: action.payload };
    case "LOGOUT_SUCCESS":
      return state;
    default:
      return state;
  }
};

export default authReducer;

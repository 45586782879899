import React, { Component } from "react"
import { Chart } from "react-google-charts"
import { Row, Col, Input } from "reactstrap"
import moment from "moment"
import { connect } from "react-redux"
import { getOrganisationStatistics } from "../../redux/actions/organisationActions"
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [["Delavec", "Ure"]],
      currentDate: moment(),
      selectedYear: moment().format("YYYY"),
      selectedMonth: moment().format("MMMM"),
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.organisation.employees !== nextProps.organisation.employees
    ) {
      const newUsers = [["Delavec", "Ure"], ...nextProps.organisation.employees]
      this.setState({
        users: newUsers,
      })
    }
  }

  componentWillMount() {
    this.getData()
  }

  getData = () => {
    console.log("Getting Data for")
    const selectedDate = moment()
      .month(this.state.selectedMonth)
      .year(this.state.selectedYear)

    const startDate = selectedDate.startOf("month").format("YYYY-MM-DD")
    const endDate = selectedDate.endOf("month").format("YYYY-MM-DD")

    this.props.getOrganisationStatistics({
      startDate: startDate,
      endDate: endDate,
    })
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <h3>Povzetek</h3>
          </Col>
          <Col md="2">
            <Input
              type="select"
              className="aphrm-c-overview__months"
              value={this.state.selectedMonth}
              onChange={e => {
                this.setState({ selectedMonth: e.target.value }, () =>
                  this.getData()
                )
              }}
            >
              {moment.months().map((month, i) => (
                <option key={i} value={month}>
                  {month}
                </option>
              ))}
            </Input>
          </Col>
          <Col md="2">
            <Input
              type="select"
              value={this.state.selectedYear}
              onChange={e => {
                this.setState({ selectedYear: e.target.value }, () =>
                  this.getData()
                )
              }}
            >
              {[...Array(5)].map((x, i) => (
                <option
                  key={i}
                  value={this.state.currentDate.format("YYYY") - i}
                >
                  {this.state.currentDate.format("YYYY") - i}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
        <Chart
          width={"100%"}
          height={"600px"}
          chartType="PieChart"
          loader={<div>Nalaganje Grafa</div>}
          data={this.state.users}
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    organisation: state.organisation,
    firebaseProfile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOrganisationStatistics: postData =>
      dispatch(getOrganisationStatistics(postData)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)

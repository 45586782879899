import React from "react";
import { Table, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getEmployees } from "../../redux/actions/employeeActions";

class Employees extends React.Component {
  componentWillMount() {
    this.props.getEmployees();
  }

  render() {
    if (this.props.firebaseProfile.isEmpty) return null;

    const { employees } = this.props.employee;
    return (
      <div className="aphrm-c-users">
        <Row>
          <Col md={10}>
            <h3>Delavci</h3>
          </Col>
          <Col md={2} className="text-right">
            <Link to={"/delavci/nov"}>
              <i className="fal fa-plus-circle aphrm-c-users__add" />
            </Link>
          </Col>
        </Row>
        <Table striped className="users-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Delavec</th>
            </tr>
          </thead>
          <tbody>
            {employees.length <= 0 && (
              <tr>
                <td colSpan="2" className="text-center">
                  Ni delavcev
                </td>
              </tr>
            )}
            {employees.length > 0 &&
              employees.map((employee, id) => (
                <tr
                  onClick={() =>
                    this.props.history.push(`/delavci/${employee.id}`)
                  }
                  key={employee.id}
                >
                  <th scope="row">{id + 1}</th>
                  <td>
                    {employee.firstName} {employee.lastName}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    employee: state.employee,
    firebaseProfile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEmployees: () => dispatch(getEmployees())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Employees);

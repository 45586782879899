import http from "../../lib/httpService"

export const getStatus = cardId => {
  return async dispatch => {
    try {
      const response = await http.get(`/status/${cardId}`)
      dispatch({ type: "GET_STATUS", payload: response })
    } catch (err) {
      dispatch({ type: "GET_STATUS_ERROR", payload: err })
    }
  }
}

export const setActivity = (cardId, status, lastActivity) => {
  return async dispatch => {
    try {
      const response = await http.put(`/status/${cardId}`, {
        status,
        lastActivity,
      })
      dispatch({ type: "GET_STATUS", payload: response })
    } catch (err) {
      dispatch({ type: "GET_STATUS_ERROR", payload: err })
    }
  }
}

const INITIAL_STATE = {
  error: null,
  activities: [],
}

const activityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ACTIVITIES":
      console.log("Get::activities", action.payload)
      return {
        ...state,
        activities: action.payload,
        error: null,
      }
    case "GET_ACTIVITIES_ERROR":
      console.log("Get::activities ERROR", action.payload)
      return {
        ...state,
        activities: [],
        error: action.payload,
      }
    case "UPDATE_ACTIVITY":
      console.log("Update::activities", action.payload)
      const activities = state.activities.map(activity => {
        if (activity.id === action.payload.id) {
          return action.payload
        }
        return activity
      })
      return {
        ...state,
        activities: activities,
        error: null,
      }
    case "UPDATE_ACTIVITY_ERROR":
      console.log("Update::activities ERROR", action.payload)
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default activityReducer

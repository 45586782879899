import http from "../../lib/httpService"

export const createEmployee = employee => {
  return async dispatch => {
    try {
      await http.put("/employees", employee)

      dispatch({ type: "CREATE_EMPLOYEE" })
    } catch (err) {
      dispatch({ type: "CREATE_EMPLOYEE_ERROR", payload: err })
    }
  }
}

export const getEmployee = employeeId => {
  return async dispatch => {
    try {
      const response = await http.get(`/employees/${employeeId}`)
      dispatch({ type: "GET_EMPLOYEE", payload: response })
    } catch (err) {
      dispatch({ type: "GET_EMPLOYEE_ERROR", payload: err })
    }
  }
}

export const updateEmployee = (employeeId, employeeData) => {
  return async dispatch => {
    try {
      const response = await http.post(`/employees/${employeeId}`, employeeData)
      dispatch({ type: "UPDATE_EMPLOYEE", payload: response })
    } catch (err) {
      dispatch({ type: "UPDATE_EMPLOYEE_ERROR", payload: err })
    }
  }
}

export const deleteEmployee = employeeId => {
  return async dispatch => {
    try {
      const response = await http.delete(`/employees/${employeeId}`)
      dispatch({ type: "DELETE_EMPLOYEE", payload: response })
    } catch (err) {
      dispatch({ type: "DELETE_EMPLOYEE_ERROR", payload: err })
    }
  }
}

export const getEmployees = () => {
  return async dispatch => {
    try {
      const response = await http.get("/employees")
      dispatch({ type: "GET_EMPLOYEES", payload: response })
    } catch (err) {
      dispatch({ type: "GET_EMPLOYEES_ERROR", payload: err })
    }
  }
}

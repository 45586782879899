import http from "../../lib/httpService"

export const getOrganisationStatistics = postData => {
  return async dispatch => {
    try {
      const response = await http.post("/statistics", postData)
      dispatch({ type: "GET_STATISTICS", payload: response })
    } catch (err) {
      dispatch({ type: "GET_STATISTICS_ERROR", payload: err })
    }
  }
}

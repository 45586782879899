export const isAdmin = currentRole => {
  return currentRole === "manager";
};

export const isManager = currentRole => {
  return currentRole === "manager";
};

export const hasPermissions = (currentRole, requiredRole) => {
  return requiredRole.includes(currentRole);
};

import { combineReducers } from "redux"

import authReducer from "./authReducer"
import employeeReducer from "./employeeReducer"
import userReducer from "./userReducer"
import statusReducer from "./statusReducer"
import activityReducer from "./activityReducer"
import organisationReducer from "./organisationReducer"
import { firebaseReducer } from "react-redux-firebase"

export default combineReducers({
  auth: authReducer,
  employee: employeeReducer,
  user: userReducer,
  status: statusReducer,
  activity: activityReducer,
  organisation: organisationReducer,
  firebase: firebaseReducer,
})

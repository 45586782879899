import React from "react"

import { Container, Row, Col } from "reactstrap"
import PrivateRoute from "../components/PrivateRoute"
import { Switch } from "react-router-dom"

import Header from "../components/header"
import Page500 from "./500"
import Dashboard from "./dashboard"
import Overview from "./overview"
import Employees from "./employees"
import Employee from "./employees/employee"

import Users from "./users"
import User from "./users/user"

import Actions from "./actions"

class Framework extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <Container fluid={false} className="aphrm-c-wrapper">
          <Row>
            <Col>
              <Switch>
                <PrivateRoute path="/" exact component={Dashboard} />
                <PrivateRoute path="/pregled" exact component={Overview} />
                <PrivateRoute path="/uporabniki" exact component={Users} />
                <PrivateRoute path="/uporabniki/nov" exact component={User} />
                <PrivateRoute
                  path="/uporabniki/:userId"
                  exact
                  component={User}
                />
                <PrivateRoute path="/delavci" exact component={Employees} />
                <PrivateRoute path="/delavci/nov" exact component={Employee} />
                <PrivateRoute
                  path="/delavci/:employeeId"
                  exact
                  component={Employee}
                />
                <PrivateRoute
                  path="/delavci/:employeeId"
                  component={Employee}
                />
                <PrivateRoute path="/akcije" exact component={Actions} />
                <PrivateRoute path="/500" component={Page500} exact />
              </Switch>
              {/* <Redirect to="/404" /> */}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

export default Framework
